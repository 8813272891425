<template>
	<button @click.stop.prevent="show">
		{{ title || $t('PRIVACY_INFORMATION') }}
	</button>
</template>
<script>
import {usePrivacyInformationStore} from "~/stores/privacyInformation";

export default {
	name: 'show-privacy-information-button',
	props: {
		title: {
			type: String,
			default: '',
		},
	},
	setup() {
		const store = usePrivacyInformationStore()

		const show = () => {
			store.show()
		}

		return {
			show,
		}
	}
}
</script>
